import React from "react";
import tr from "../img/tire_red.png";
import pr from "../img/pr.png";

function importAll(r) {
    let images = {};
    r.keys().map((item) => ( images[item.replace('./', '')] = r(item)));
    return images;
}
const images = importAll(require.context('../img/icons/', false, /\.(png|jpe?g|svg)$/));


const Package = (props) => {
    const {packageName} = props;
    let mostPoppular;
    if (packageName.showIsMostPoppular) {
        mostPoppular = <div className="package-single-mostpoppular"><p>Hit!</p></div>
    }
    return (
        <div className="col-lg-4">
            <div className="package-single">
                {mostPoppular}
                <div className="package-single-name">
                    <p>{packageName.name}</p>
                </div>
                {packageName.name === 'Pakiet PLUS' &&
                    <div className="package-single-desc">
                       <p>Podstawowa usługa detailingu oparta o JEDNOETAPOWĄ korektę lakieru, która w krótkim czasie, pozwala usunąć ok. 60-70% defektów - rys, oksydacji i hologramów. Lakier zabezpieczany jest klasycznym WOSKIEM, który doskonale podkreśla blask i chroni przed czynnikami atmosferycznymi. Pakiet PLUS rekomendowany jest dla klientów oczekujących skutecznego i szybkiego odświeżenia lakieru auta.</p>
                    </div>
                }
                {packageName.name === 'Pakiet PREMIUM' &&
                    <div className="package-single-desc">
                       <p>Kompleskowa usługa detailingu oparta o DWUETAPOWĄ korektę lakieru, dzięki której możemy usunąć ok. 80-90% defektów lakieru. Lakier zabezpieczany jest profesjonalną, JEDNOSKŁADNIKOWĄ powłoką kwarcową G'zox Real Glass Coat. Pakiet rekomendowany dla klientów wymagających wyraźnej poprawy wyglądu swoich aut i profesjonalnego zabezpieczenia.</p>
                    </div>
                }
                {packageName.name === 'Pakiet TOP' &&
                    <div className="package-single-desc">
                       <p>Bezkompromisowy pakiet detailingowej pielęgnacji. TRZYETAPOWA korekta pozwala usunąć niemal wszystkie defekty lakieru, a jego ochronę zapewni topowa, DWUSKŁADNIKOWA powłoka kwarcowa G'zox Hi-MOHS. Pakiet rekomendowany dla klientów oczekujących perfekcji.</p>
                    </div>
                }
                <div className="package-single-features">
                {/* {packageName.name === 'Pakiet zabezpieczenie' &&
                    <div className="package-single-features-item">
                    <img className="package-single-features-item-icon" alt="" src={tr}></img><p className="package-single-features-item-name rpack">Wymiana opon GRATIS!*</p>
                </div>
                } */}
                {packageName.features.map((feature)=>{
                    const temp = feature.icon;
                    return (                        
                        <div key={feature.id} className="package-single-features-item">
                            <img className="package-single-features-item-icon" alt="" src={images[temp.toLowerCase()+'.svg']}></img><p className="package-single-features-item-name">{feature.feature}</p>
                        </div>
                    )
                })}
                {packageName.name === 'Pakiet Standard' &&
                    <div className="package-single-features-item">
                    <img className="package-single-features-item-icon" alt="" src={pr}></img><p className="package-single-features-item-name rpack">Zabezpieczenie lakieru syntetycznym QD GRATIS!</p>
                </div>
                }
                </div>
                <div className="packge-single-aditional-info">
                        {packageName.time !== 0 &&
                            <div className="package-single-time">
                                <p>Czas: {packageName.time} min</p>
                            </div>
                        }
                    <div className="package-single-price">
                        <p>od {packageName.price} zł</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Package