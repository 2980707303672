import {React, useState, useEffect} from "react";
import axios from 'axios';
import { Cookies, useCookies } from 'react-cookie';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pl from 'date-fns/locale/pl';
import checkGreen from "../img/checkgreen.png";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import addNewEvent from "./addNewEvent";
import isAtFreeTimeSlot from "./isOnFreeTimeSlot";
// import calicon from "../img/calicon.png";
// import cloicon from "../img/cloicon.png";
import ReactPixel from 'react-facebook-pixel';

const InnerModal = (props) => {
    const {setOpen, service} = props;
    const [eventAdded, setEventAdded] = useState(false)
    const [bookedTimes, setBookedTimes] = useState([])
    const [cookies, setCookie] = useCookies()
    const [description, setDescription] = useState()
    let mailName;
    let mailPhone;
    let currServiceId;
    const [sendMail , setSendMail] = useState(false);
    const [startDate, setStartDate] = useState();
    const [startTime, setStartTime] = useState();
    const outOfOrderTimes = [
        setHours(setMinutes(new Date(), 0), 0),
        setHours(setMinutes(new Date(), 30), 0),
        setHours(setMinutes(new Date(), 0), 1),
        setHours(setMinutes(new Date(), 30), 1),
        setHours(setMinutes(new Date(), 0), 2),
        setHours(setMinutes(new Date(), 30), 2),
        setHours(setMinutes(new Date(), 0), 3),
        setHours(setMinutes(new Date(), 30), 3),
        setHours(setMinutes(new Date(), 0), 4),
        setHours(setMinutes(new Date(), 30), 4),
        setHours(setMinutes(new Date(), 0), 5),
        setHours(setMinutes(new Date(), 30), 5),
        setHours(setMinutes(new Date(), 0), 6),
        setHours(setMinutes(new Date(), 30), 6),
        setHours(setMinutes(new Date(), 0), 7),
        setHours(setMinutes(new Date(), 30), 7),
        // setHours(setMinutes(new Date(), 30), 17),
        // setHours(setMinutes(new Date(), 0), 18),
        // setHours(setMinutes(new Date(), 30), 18),
        // setHours(setMinutes(new Date(), 0), 19),
        // setHours(setMinutes(new Date(), 30), 19),
        // setHours(setMinutes(new Date(), 0), 20),
        setHours(setMinutes(new Date(), 30), 20),
        setHours(setMinutes(new Date(), 0), 21),
        setHours(setMinutes(new Date(), 30), 21),
        setHours(setMinutes(new Date(), 0), 22),
        setHours(setMinutes(new Date(), 30), 22),
        setHours(setMinutes(new Date(), 0), 23),
        setHours(setMinutes(new Date(), 30), 23),
    ]
    const [carwashBookedTimes, setCarwashBookedTimes] = useState([])
    const [allStations, setAllStations] = useState();
    const [selectedStation, setSelectedStation] = useState("all");
    const [SelectedStationName, setSelectedStationName] = useState("none");
    const [disabled, setDisabled] = useState(true)
    const isWeekday = (date) => {
        const day = date.getDay()
        return day !== 0 && day !== 6;
    }
    const setNameToMail = (e) => {mailName = e.target.value;}
    const setPhoneToMail = (e) => {mailPhone = e.target.value;}
    const sendInfoMail = (e) => {
        let myForm = document.querySelector('#myformmaster');
        e.preventDefault();
        myForm.checkValidity();
        myForm.reportValidity();
        if (myForm.checkValidity()){
            //miejsce na wysyłkę maila
            setSendMail(true);
        } else {
        }
    };
    const handleConfirm = (e) => {
        console.log('wysylka klik');
            var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
            sendInfoMail(e)

            axios.get('https://cms.soft99.eu/calendar-events?_where[0][start_gte]='+ startDate.toISOString() +'&_where[0][end_lte]='+ new Date(startDate.getTime() + 86400000).toISOString(),
            {
                headers: { Authorization: `Bearer ` + cookies.JWTToken }
            }).then(response=>{
                var localISOStartDate = (new Date(startDate - tzoffset)).toISOString();
                var localISOStartTime = (new Date(startTime)).toISOString();
                var localISOEndDate = (new Date(startDate.setHours(startDate.getHours() +1) - tzoffset)).toISOString();
                var localISOEndTime = (new Date(startTime.setHours(startTime.getHours() +1))).toISOString();
        
                let start = localISOStartDate.slice(0,-13)+localISOStartTime.slice(-13);
                let end = localISOEndDate.slice(0,-13)+localISOEndTime.slice(-13);
                startDate.setHours(startDate.getHours() -1)
                startTime.setHours(startTime.getHours() -1)
                
                let newClient = {
                    "name": mailName,
                    "email": "nieznany@email.pl",
                    "phone": mailPhone,
                    "calendar_vehicles": []
                }
                if(selectedStation === "all"){
        
                }
                else if([4,5,6].includes(parseInt(selectedStation))){
                    if(isAtFreeTimeSlot(start.slice(0,-4)+"000Z", end.slice(0,-4)+"000Z", parseInt(selectedStation), response.data)){
                        ReactPixel.track('Contact', {
                            fn: mailName,
                            ph: mailPhone
                        });
                        addNewEvent(mailName, mailPhone, start, end, allStations, selectedStation, "Szybki przeglad + wycena", cookies.JWTToken, setEventAdded)
                    }
                }
                else if(selectedStation==="carwash"){
                    if(isAtFreeTimeSlot(start.slice(0,-4)+"000Z", end.slice(0,-4)+"000Z", 1, response.data)){
                        ReactPixel.track('Contact', {
                            fn: mailName,
                            ph: mailPhone
                        });
                        addNewEvent(mailName, mailPhone, start, end, allStations, 1, description, cookies.JWTToken, setEventAdded)
                    }
                    else if(isAtFreeTimeSlot(start.slice(0,-4)+"000Z", end.slice(0,-4)+"000Z", 2, response.data)){
                        ReactPixel.track('Contact', {
                            fn: mailName,
                            ph: mailPhone
                        });
                        addNewEvent(mailName, mailPhone, start, end, allStations, 2, description, cookies.JWTToken, setEventAdded)
                    }
                    else if(isAtFreeTimeSlot(start.slice(0,-4)+"000Z", end.slice(0,-4)+"000Z", 3, response.data)){
                        ReactPixel.track('Contact', {
                            fn: mailName,
                            ph: mailPhone
                        });
                        addNewEvent(mailName, mailPhone, start, end, allStations, 3, description, cookies.JWTToken, setEventAdded)
                    }
                    else{
                        alert("Wygląda na to, że ktoś właśnie zarezerwował ten termin... Prosimy wybrać inną godzinę.");
                    }
                }
            })
    }
    const addTimeSlotsToBooked = () =>{
        bookedTimes.length = 0
            axios.get('https://cms.soft99.eu/calendar-events/?_where[0][start_gte]='+ startDate.toISOString() +'&_where[0][end_lte]='+ new Date(startDate.getTime() + 86400000).toISOString(),
            {
                headers: { Authorization: `Bearer ` + cookies.JWTToken }
            })
            .then(response=>{
                    if(selectedStation !== "carwash"){
                        response.data.forEach((event)=>{
    
                            if(event.calendar_station.id == selectedStation){
                                let start = new Date(event.start)
                                let end = new Date(event.end)
                                let numberOfSlots = Math.ceil((end-start)/60000/30)
            
                                for(let i=0; i<numberOfSlots; i++){
                                    let newBookedSlot = setHours(setMinutes(new Date(), start.getMinutes()), start.getHours()).getTime()+i*30*60000
                                    bookedTimes.push(newBookedSlot)
                                }
                                bookedTimes.push(setHours(setMinutes(new Date(), start.getMinutes()), start.getHours()).getTime()-30*60000)
                                setBookedTimes([...bookedTimes])
                            }
                        })
                    }
                    else{
                        response.data.forEach((event)=>{
                            if( event.calendar_station.id === 1 || 
                                event.calendar_station.id === 2 ||
                                event.calendar_station.id === 3){
                                    
                                let start = new Date(event.start)
                                let end = new Date(event.end)
                                let numberOfSlots = Math.ceil((end-start)/60000/30)
            
                                for(let i=0; i<numberOfSlots; i++){
                                    let newBookedSlot = setHours(setMinutes(new Date(), start.getMinutes()), start.getHours()).getTime()+i*30*60000
                                    carwashBookedTimes.push(newBookedSlot)
                                }
                                carwashBookedTimes.push(setHours(setMinutes(new Date(), start.getMinutes()), start.getHours()).getTime()-30*60000)
                                setCarwashBookedTimes([...carwashBookedTimes])
                            }
                        })
                        carwashBookedTimes.forEach(timeSlot=>{
                            let result = 0
                            for(let i = 0; i < carwashBookedTimes.length; i++){
                                if(new Date(carwashBookedTimes[i]).getHours() + ":" + new Date(carwashBookedTimes[i]).getMinutes() == new Date(timeSlot).getHours() + ":" + new Date(timeSlot).getMinutes())
                                {result++}
                            }
                            if(result >= 3){
                                bookedTimes.push(timeSlot)
                            }
                            setBookedTimes([...bookedTimes])
                        })
                        setCarwashBookedTimes([])
                    }
            })
    }

    useEffect(()=>{
        axios.get('https://cms.soft99.eu/calendar-stations?isDeleted_eq=false'
            ,
            {
                headers: { Authorization: `Bearer ` + cookies.JWTToken}
            })
            .then(response=>{
                if(response.status===200){
                    if(service){
                        currServiceId = service.id;
                        switch(service.id){
                            case 1: setSelectedStation(6)
                                break;
                            case 2: setSelectedStation(4)
                                break;
                            case 3: setSelectedStation(5)
                                break;
                            case 4: setSelectedStation(4)
                                break;
                            case 5: setSelectedStation("carwash")
                                break;
                            case 6: setSelectedStation(4)
                                break;
                            case 7: setSelectedStation(4)
                                break;
                            case 10: setSelectedStation(4)
                                break;
                        }
                    }
                    setAllStations(response.data);
                    if( selectedStation === "all"){
                        setDisabled(true)
                    }
                    else if( selectedStation === "carwash"){
                        setDisabled(false)
                        addTimeSlotsToBooked()
                    }
                    else{
                        setDisabled(false)
                        if(startDate){
                            addTimeSlotsToBooked()
                        }
                    }
                }
            })
            axios.post('https://cms.soft99.eu/auth/local',
            {
                identifier: "api",
                password: "VFSuniCEqJ295Pb$"
            }).then(response=>{
                setCookie('JWTToken', response.data.jwt, { path: '/' })
            })

    },[selectedStation, startDate, cookies]) 

    return(
        <div className="innerwraper">
            {allStations && <div className="beforemail" style={{display: (sendMail) ? 'none' : 'initial' }}>
                <p className="pop-intro-text">Zarezerwuj termin wizyty</p>
                <form method="post" className="myform" action="" id="myformmaster">
                    {!service && <select name="station"  className="innerinput" onChange={(e)=>{
                        setStartDate()
                        setStartTime()
                        setSelectedStation(e.target.value)
                        setSelectedStationName(e.target[e.target.selectedIndex].text)}}>
                        <option value="all">Wybierz stanowisko</option>
                        <option value={6}>Detailing</option>
                        <option value={4}>Diagnostyka komputerowa</option>
                        {/* <option value={5}>Felgi i opony</option> */}
                        <option value={4}>Klimatyzacja</option>
                        <option value={"carwash"}>Myjnia</option>
                        <option value={4}>Przeglądy okresowe</option>
                        <option value={4}>Układ jezdny</option>
                    </select>}
                    <select name="specificservice"  className="innerinput" disabled={selectedStation==="all"} onChange={(e)=>{setDescription(e.target.value)}}>
                        {selectedStation==="carwash" && <option value="all">Wybierz usługę</option>}
                        {([4,5,6].includes(parseInt(selectedStation)) && SelectedStationName != "Przeglądy okresowe") && <option value="Szybki przegląd + wycena">Szybki przegląd + wycena</option>}
                        {([4,5,6].includes(parseInt(selectedStation)) && SelectedStationName === "Przeglądy okresowe") && <option value="Szybki przegląd + wycena">Przegląd</option>}
                        {selectedStation==="carwash" && <option value={"Mycie zewnętrzne"}>Mycie zewnętrzne</option>}
                        {selectedStation==="carwash" && <option value={"Mycie wewnętrzne"}>Mycie wewnętrzne</option>}
                        {selectedStation==="carwash" && <option value={"Komplet"}>Komplet</option>}
                        {selectedStation==="carwash" && <option value={"Pakiet HYDROFOBOWOŚĆ/ZABEZPIECZENIE/POŁYSK"}>Pakiet HYDROFOBOWOŚĆ/ZABEZPIECZENIE/POŁYSK</option>}
                        {selectedStation==="carwash" && <option value={"Pakiet z powłoką"}>Pakiet z powłoką</option>}
                        {selectedStation==="carwash" && <option value={"Pakiet z woskiem płynnym"}>Pakiet z woskiem płynnym</option>}
                        {selectedStation==="carwash" && <option value={"Pakiet z woskiem twardym"}>Pakiet z woskiem twardym</option>}
                    </select>
                    <div className="innerpick-pop innerdatapop">
                        <DatePicker 
                        selected={startDate}
                        className="databadania-pop innerinput"
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Data"
                        dateFormat="dd-MM-yyyy"
                        minDate={new Date()}
                        locale={pl}
                        disabled={disabled}
                    />
                    </div>
                    <div className="innerpick-pop innertimepop">
                        <DatePicker
                            className="czasbadania-pop innerinput"
                            selected={startTime}
                            onChange={(date) => setStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            placeholderText="Godzina"
                            timeIntervals={30}
                            locale={pl}
                            excludeTimes={
                                bookedTimes.concat(outOfOrderTimes)
                            }
                            dateFormat="HH:mm"
                            disabled={!startDate || disabled}
                        />
                    </div>
                    <br />
                    <input type="text" name="imie" placeholder="Imię" className="innerinput" onChange={(e)=>{setNameToMail(e)}} required /><br />
                    <input type="tel" name="tel" placeholder="Telefon (+48)" className="innerinput" onChange={(e)=>{setPhoneToMail(e)}} required /><br />
                    <input type="submit" value="Potwierdzam termin" className="innersubmit" onClick={(e)=>{handleConfirm(e)}} /><br />
                    <div className="checkwrapper1">
                        <input type="checkbox"  id="vehicle1" name="vehicle1" value="true" className="innercheck" required />
                        <label htmlFor="vehicle1" className="innerchecklabel">Zgadzam się na przesyłanie informacji handlowych za pośrednictwem środków komunikacji elektronicznej lub na kontakt telefoniczny.</label>
                    </div>
                    <div className="checkwrapper2">
                        <input type="checkbox" id="vehicle2" name="vehicle2" value="true" className="innercheck" required />
                        <label htmlFor="vehicle2" className="innerchecklabel">Potwierdzam zapoznanie się z informacją administratora danych. Administratorem danych jest Nowy Samochód S.A., ul. Zbyszka Cybulskiego 3, 00-725 Warszawa.</label>
                    </div>
                    <span className="output_message"></span>
                </form>
            </div>}
            {/* after sending */}
            <div className="aftermail" style={{display: (sendMail && eventAdded) ? 'initial' : 'none' }}>
                <p className="aftermail-title"><img className="checkGreen" src={checkGreen} alt="checkok"/> Potwierdzamy termin Twojej wizyty!</p>
                <p className="aftermail-text">Zapraszamy do naszej Stacji Kontroli Pojazdów<br /><span>61-568 Poznań, ul. Wierzbięcice 2A</span></p>
                {/* <div className="aftermail-dtbox">
                    <div className="aftermail-dtbox-item"><img src={calicon}/> {startDate}</div>
                    <div className="aftermail-dtbox-item"><img src={cloicon}/> Godzina {startTime}</div>
                </div> */}
                <div className="aftermail-close-box">
                    <button onClick={(e)=> setOpen(false)}>Zamknij okno</button>
                </div>
            </div>
      </div>
    )
}

export default InnerModal;
