import {React, useEffect, useState} from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import InnerModal from "./InnerModal";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import bphone from "../img/bphone.png";
import bmail from "../img/bmail.png";
import bfb from "../img/bfb.png";
import bis from "../img/bis.png";
import byt from "../img/byt.png";

const Contact = () => {
    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'instant',
        });
      }, []);
    //   const onOpenModal = () => setOpen(true);
      const onCloseModal = () => setOpen(false);
      const [open, setOpen] = useState(false);
      const [services, setServices] = useState();
  

      useEffect(()=>{
        fetch('https://test.cms.soft99.eu/garage-services')
            .then(resp => resp.json())
            .then((data) => {
                setServices(data);
            })
    },[])

    return(
        <div>
            <header>
                <Navbar services={services}/>
            </header>
            <Modal open={open} onClose={onCloseModal} center>
                <InnerModal />
            </Modal>
            <section>
                <div className="container contactpage">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="danekontakt-con">
                                <p className="service-desc-par"><b>Soft99 Garage</b></p>
                                <p className="service-desc-par">ul. Wierzbięcice 2A<br />61-568 Poznań</p>
                                <p className="service-desc-par">
                                    <a className="contact-active-link" href="tel:+48607285285"><img className="social-footer-icon" src={bphone} alt=""></img> +48 607 285 285</a><br />
                                    <a className="contact-active-link" href="mailto:kontakt@soft99garage.pl"><img className="social-footer-icon" src={bmail} alt=""></img> kontakt@soft99garage.pl</a>
                                </p>
                                <p className="service-desc-par">
                                    <a href="https://www.facebook.com/Soft99Garage" target="_blank" className="contact-social-link"><img className="social-footer-icon" src={bfb} alt=""></img> facebook.com/Soft99Garage</a><br />
                                    <a href="https://www.instagram.com/soft99_garage/" target="_blank" className="contact-social-link"><img className="social-footer-icon" src={bis} alt=""></img> instagram.com/soft99_garage</a><br />
                                    <a href="https://www.youtube.com/c/Soft99Europe" target="_blank" className="contact-social-link"><img className="social-footer-icon" src={byt} alt=""></img> youtube.com/c/Soft99Europe</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="mapakontakt-con">
                                <iframe className="mapakontakt" title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.3617570911265!2d16.919403215790222!3d52.40011955285901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47045b0b68e75319%3A0xac09f3d75a300baa!2sSoft99%20Garage!5e0!3m2!1spl!2spl!4v1652879770662!5m2!1spl!2spl"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Contact;